
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo(): any {
        return {
            title: 'Style guide',
        };
    },

    components: {},

    props: {},

    data() {
        return {};
    },

    methods: {},
});

